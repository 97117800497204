import {
  CircularProgress,
  TextField,
  Paper,
  Button,
  Typography
} from '@material-ui/core';
import { Link } from 'gatsby';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { ExternalLink } from 'react-feather';
import { SuccessMessage, WarningMessage } from '../components/Alerts';
import { HidableBranding as Branding } from '../components/Branding';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { SEO } from '../components/SEO';
import { Subheading } from '../components/Subheading';
import { Text } from '../components/Text';
import Layout, { Wrapper } from '../layouts/Layout';
import { useCurrentUser } from '../services/currentUser';
import styled from '../styled';
import { login } from '../services/auth';

const Img = styled('img')`
  max-width: 400px;
  display: block;
  margin: ${(p) => p.theme.spacing(1)}px auto;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  border: 1px solid ${(p) => p.theme.palette.grey['400']};
`;

const StyledA = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
`;

const Loader = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  svg {
    color: inherit;
  }
`;

const FormWrapper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px;
  max-width: 400px;
  margin: ${(p) => p.theme.spacing(4)}px auto;
`;

const INITIAL_EMAIL = '';
const IndexPage = () => {
  const { isAuthenticated } = useCurrentUser();
  const urlParam = (() => {
    if (typeof window === 'undefined') {
      return INITIAL_EMAIL;
    }
    const params = new URLSearchParams(window.location.search);
    return params.get('email') || INITIAL_EMAIL;
  })();
  const [mode, setMode] = useState<'resetPassword' | 'login'>('resetPassword');
  const [email, setEmail] = useState(urlParam);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Migrate"
        siteUrl="https://bloggingfordevs.com"
        description="Migrate to a Blogging for Devs account from your existing Circle account"
        pathname="migrate/"
        noIndex
      />
      <Wrapper>
        <div
          style={{
            margin: '0 auto 48px',
            textAlign: 'center'
          }}
        >
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Migration Guide
          </Typography>
          <Text>
            Migrate to a unified Blogging for Devs account in just one minute!
          </Text>
        </div>
        <WarningMessage>
          <p>In this process, you will:</p>
          <ol>
            <li>Reset your password via email</li>
            <li>Log in to this website</li>
            <li>Log out of the community, and log back in</li>
          </ol>
          <p>
            This means logging in <em>twice</em> (once in the website, once in
            the community), using the same account. This is because of OAuth and
            cookies &mdash; you know how it goes!
            <br />
            <br />
            Should take 60 seconds! Let's go:
          </p>
        </WarningMessage>
        <br />
        <Subheading>Step 1. Reset your password</Subheading>
        <br />
        <Typography variant="h6" component="p" paragraph>
          I've already imported your profile to the new account. So just reset
          your password below, check your email, choose a new password, and
          return to this page.
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Then, use the new password to log into this website.
        </Typography>
        {isAuthenticated && (
          <SuccessMessage>
            Fabulous, you're logged in to the website. Now go to Step 2 to use
            this same account for the community.
          </SuccessMessage>
        )}
        {!isAuthenticated && (
          <FormWrapper>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setLoading(true);
                setErrorMsg('');
                setResetPasswordEmailSent(false);

                if (mode === 'resetPassword') {
                  firebase
                    .auth()
                    .sendPasswordResetEmail(email)
                    .then(() => {
                      setLoading(false);
                      setResetPasswordEmailSent(true);
                      setMode('login');
                    })
                    .catch((resp) => {
                      const msg = resp.message;
                      setLoading(false);
                      setErrorMsg(msg);
                    });
                }

                if (mode === 'login') {
                  login(email, password)
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((resp) => {
                      const msg = resp.message;
                      setLoading(false);
                      setErrorMsg(msg);
                    });
                }
              }}
            >
              {resetPasswordEmailSent && (
                <SuccessMessage style={{ marginTop: '24px' }}>
                  Perfect! Now open your email, click the link to choose a new
                  password. Afterwards, log in below and then proceed to Step 2.
                </SuccessMessage>
              )}
              {mode === 'resetPassword' && (
                <Typography variant="body1" component="p" paragraph>
                  Please use the same email address you use currently use for
                  the community:
                </Typography>
              )}
              <TextField
                type="email"
                variant="filled"
                label="Email"
                value={email}
                fullWidth
                InputProps={{
                  style: { backgroundColor: 'white', borderRadius: '8px' },
                  disableUnderline: true
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                margin="normal"
              />
              {mode === 'login' && (
                <TextField
                  type="password"
                  variant="filled"
                  label="Password"
                  value={password}
                  fullWidth
                  InputProps={{
                    style: { backgroundColor: 'white', borderRadius: '8px' },
                    disableUnderline: true
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                  margin="normal"
                />
              )}
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                style={{
                  padding: '12px 24px',
                  fontSize: '16px',
                  marginBottom: '12px',
                  fontWeight: 700
                }}
                disabled={loading}
              >
                {loading && <Loader />}
                {mode === 'resetPassword' ? 'Reset your password' : 'Log in'}
              </Button>
              {errorMsg.length > 0 && (
                <WarningMessage>{errorMsg}</WarningMessage>
              )}
            </form>
          </FormWrapper>
        )}
        <br />
        <br />
        <Subheading>Step 2. Log out of the Community</Subheading>
        <br />
        <Typography variant="h6" component="p" paragraph>
          Open the{' '}
          <StyledA href="https://community.bloggingfordevs.com" target="_blank">
            Blogging for Devs Community <ExternalLink size={16} />
          </StyledA>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Click your profile picture, and then choose "Sign out".
        </Typography>
        <Img src="/images/migrate/circle-signout.jpg" />
        <br />
        <Typography variant="h6" component="p" paragraph>
          This will bring you to the login page, where you can sign in again
          with your new password.
        </Typography>
        <br />
        <br />
        <Subheading>Step 3. Log in again and you're done!</Subheading>
        <br />
        <Typography variant="h6" component="p" paragraph>
          Click "Continue with Blogging for Devs" on the Community login page.
        </Typography>
        <Img src="/images/migrate/circle-signin.jpg" />
        <br />
        <Typography variant="h6" component="p" paragraph>
          This will take you to a new login portal. Use the same email and
          password you just set to log in to the community.
        </Typography>
        <br />
        <Img src="/images/migrate/oauth-login.jpg" />
        <br />
        <Typography variant="h6" component="p" paragraph>
          You're all migrated! Here's what's changed:
        </Typography>
        <Typography variant="h6" component="ul" paragraph>
          <li>
            You'll manage your profile on our{' '}
            <StyledLink to="/profile/">Profile</StyledLink> page
          </li>
          <li>
            You can RSVP for events on the{' '}
            <StyledLink to="/events/">Events</StyledLink> page
          </li>
          <li>
            <strong>Soon:</strong> You'll have access to members-only Articles
            and Guides
          </li>
          <li>
            Lots more planned! Keep an eye on the{' '}
            <StyledA
              href="https://community.bloggingfordevs.com/c/meta"
              target="_blank"
            >
              Meta
            </StyledA>{' '}
            space in the Community for announcements.
          </li>
        </Typography>
        <Typography variant="h6" component="p" paragraph>
          Thank you SO MUCH for following this migration guide, I truly
          appreciate it!
          <br />
          <br />
          If you got stuck or had any issues, please either DM me in the
          community or email me at{' '}
          <StyledA href="mailto:hi@bloggingfordevs.com">
            hi@bloggingfordevs.com
          </StyledA>{' '}
          so I can improve it :)
        </Typography>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
