import styled from '../styled';

export const Text = styled('p')`
  color: ${(p) => p.theme.palette.grey['A200']};
  max-width: 500px;
  margin: 0 auto;
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.grey['A200']};
  }
`;
